import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from "react-helmet"
import { withPrefix, Link } from "gatsby"
import Description from "../components/description"
import { useLocation } from "@reach/router" // this helps tracking the location


import Mini1 from '../images/blobs/mini1.inline.svg'
import Mini2 from '../images/blobs/mini2.inline.svg'


const Page = ({ data }) => {
  const page = data.wpPage


  const location = useLocation();

  const availableReports = [
    "raport-matka-polka-by-mediahub-2021",
    "raport-mediahub-konsument-w-warunkach-niepewnosci",
    "raport-covid-track-by-mullenlowe-mediahub-2021",
    "raport-covid-track-by-mullenlowe-mediahub-2021-2",
    "raport-mediahub-generacja-z-rzeczywistosc-mlodych-w-kontrze-do-utrwalonych-stereotypow",
    "raport-mediahub-pracownicy-50plus-branzy-reklamowej"
  ]
  

  const params = new URLSearchParams(location.search);


  let pageReport = "";

  params.forEach(function (paramValue, pramKey) {
    
    if(pramKey == "report") {

      const reportIndex = availableReports.indexOf(paramValue)

      if(reportIndex != -1)  {
        pageReport = availableReports[reportIndex];
      }


    }

  });


  let reportLink = "";

  let downloadParam = "";

  if(pageReport != "") {
    reportLink = "raporty_mlmh/" + pageReport + ".pdf";
    downloadParam = pageReport + ".pdf";
  }




  let containerCss = "container-narrow";

  let showReportForm = false;
  let showReportFormOrder = false;
  let showOtherReports = false;

  if(page.id == 'cG9zdDo3MjQ0' || //Na podany adres mail wysłaliśmy prośbę o zgodę na komunikacje. Kliknij w link weryfikacyjny i pobierz raport.
  page.id == 'cG9zdDo3MjQ5' || //Dziękujemy za potwierdzenie adresu mail.
  page.id == 'cG9zdDo3MzAz') //Dziękujemy za zainteresowanie naszymi publikacjami.
  {  
    
    containerCss = "container-narrow report-container"
  }

  if(page.id == 'cG9zdDo3MjQ5' || //Dziękujemy za potwierdzenie adresu mail.
  page.id == 'cG9zdDo3MzAz')  //Dziękujemy za zainteresowanie naszymi publikacjami.
  {
    showOtherReports = true;
  }


 

  if(page.id == 'cG9zdDo3MjI0' ||
  page.id == 'cG9zdDo3Mjc3' ||
  page.id == 'cG9zdDo3Mjkw' ||
  page.id == 'cG9zdDo3Mjk2' ||
  page.id == 'cG9zdDo3MzE4' ||
  page.id == 'cG9zdDo3Mzc3'
  ) {

    showReportForm = true

    containerCss = "container-narrow report-container"

  }

  

  if(page.id == "cG9zdDo3MzE4") {
    showReportFormOrder = true
  }

  let reportBlockText= "Aby pobrać raport wypełnij formularz poniżej";

  // if(partialReport) {
  // //  reportBlockText= "Wypełnij formularz i odbierz wersję demo raportu";
  // }


  let otherReports = [
    {
      "id": "raport-covid-track-by-mullenlowe-mediahub-2021-2",
      "date": "2 października 2022",
      "category": "Raporty",
      "title": "RAPORT – COVID-TRACK by MediaHub 2 – Jak po roku pandemii zmieniły się zachowania konsumenckie",
      "url": "/raport-covid-track-by-mediahub-2-jak-po-roku-pandemii-zmienily-sie-zachowania-konsumenckie/",
      "imageUrl": "/static/1c3c9cf92ad5cae7e8ec4744932b4e04/cc9ed/RAPORT.webp"
    },
    {
      "id": "raport-matka-polka-by-mediahub-2021",
      "date": "2 października 2022",
      "category": "Raporty",
      "title": "RAPORT – Matka Polka. Od światopoglądu po decyzje zakupowe matek w Polsce",
      "url": "/raport-matka-polka-od-swiatopogladu-po-decyzje-zakupowe-matek-w-polsce/",
      "imageUrl": "/static/9b386c11195c7934f616c4662d4c3f5d/cc9ed/b1635173500689.webp"
    },
    {
      "id": "raport-covid-track-by-mullenlowe-mediahub-2021",
      "date": "2 października 2022",
      "category": "Raporty",
      "title": "RAPORT – COVID-TRACK by MediaHub – Jak epidemia wpłynęła na styl życia Polaków?",
      "url": "/covid-track-by-mediahub-jak-epidemia-wplynela-na-styl-zycia-polakow/",
      "imageUrl": "/static/1c3c9cf92ad5cae7e8ec4744932b4e04/cc9ed/RAPORT.webp"
    },
    {
      "id": "raport-mediahub-konsument-w-warunkach-niepewnosci", 
      "date": "2 października 2022",
      "category": "Raporty",
      "title": "RAPORT – „Konsument w warunkach niepewności – zmiany w finansach i zachowaniach zakupowych”",
      "url": "/raport-konsument-w-warunkach-niepewnosci-zmiany-w-finansach-i-zachowaniach-zakupowych/",
      "imageUrl": "/static/61f9d936b25dcc60e0f6df3a7fe9155b/31cc3/278569996_10160432960593463_613020030825951325_n.webp"
    },
    {
      "id": "raport-mediahub-generacja-z-rzeczywistosc-mlodych-w-kontrze-do-utrwalonych-stereotypow", 
      "date": "19 października 2022",
      "category": "Raporty",
      "title": "RAPORT Mediahub – „Konsument w warunkach niepewności – zmiany w finansach i zachowaniach zakupowych”",
      "url": "/raport-mediahub-generacja-z-rzeczywistosc-mlodych-w-kontrze-do-utrwalonych-stereotypow/",
      "imageUrl": "/static/e8e5e4137c0e30024e8692cb18a6d9e5/c512e/raport_gen_z.webp"
    },
    {
      "id": "raport-mediahub-pracownicy-50plus-branzy-reklamowej", 
      "date": "27 czerwca 2024",
      "category": "Raporty",
      "title": "RAPORT MEDIAHUB: 50-latkowie w branży reklamowej",
      "url": "/raport-mediahub-pracownicy-50plus-branzy-reklamowej/",
      "imageUrl": "/static/4ec829bdcbc33ad971a7040415056304/c512e/50latkowie_w_branzy_reklamowej.webp"
    }


  ];


  return (
    <Layout>
      <Seo
        title={page.title}
        seoTitle={page.SeoMeta.seoTitle}
        description={page.SeoMeta.seoDescription}
      />
      {showReportForm &&
      <Helmet>
        <script src={withPrefix('reports.js')} type="text/javascript" />
    </Helmet>
    }

{showOtherReports &&
      <Helmet>
        <script src={withPrefix('reports_available.js')} type="text/javascript" />
    </Helmet>
    }

{showReportFormOrder &&
      <Helmet>
        <script src={withPrefix('reports_order.js')} type="text/javascript" />
    </Helmet>
    }


      <section className="container">
         <div className="blob-wrap">
          <Mini2 style={{left: '-50%'}} />
        </div>
        <div className="blob-wrap">
          <Mini1 style={{right: '-50%', transform: 'translateY(130%)'}}/>
        </div> 
        <div className={containerCss}>
          <h1 style={{marginTop: '20px'}}>{page.title}</h1>
          { page.Description.description && (
          <Description {...page.Description} />
          )}
          <div dangerouslySetInnerHTML={{ __html: page.content }} />

            {showReportForm &&
            
            <div id="pobierz-raport">

              
            <div id={"report-form-wrapper"}>
            <form id={"report-form"} method="post" action="/">
                <div className="report-form-title">{reportBlockText}</div>
<div class="register-form-col">
                <div id={"form-report-email-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-email"} className={"report-form-label"}>
                    Email
                  </label>
                  <input type="email" name={"report-email"} id={"report-email"} className={"report-form-text-input"} />
                </div>

                <div id={"form-report-full-name-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-full-name"} className={"report-form-label"}>
                    Imię
                  </label>
                  <input type="text" name={"report-full-name"} id={"report-full-name"} className={"report-form-text-input"} />
                </div>

                </div>
                <div class="register-form-col">
                <div id={"form-report-company-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-company"} className={"report-form-label"}>
                    Firma
                  </label>
                  <input type="text" name={"report-company"} id={"report-company"} className={"report-form-text-input"} />
                </div>

                <div id={"form-report-job-position-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-job-position"} className={"report-form-label"}>
                    Stanowisko
                  </label>
                  <input type="text" name={"report-job-position"} id={"report-job-position"} className={"report-form-text-input"} />
                </div>
           </div>     
               
                <div id={"form-terms-1-wrapper"} className={"report-checkbox"}>
                  <input type={"checkbox"} id={"terms-1"} name={"terms-1"} value={1} className={"report-form-checkbox"} required />
                  <label htmlFor={"terms-1"}  className={"report-checkbox-label"}> Wyrażam zgodę na przetwarzanie przez Octopus Media Sp. z o.o. z siedzibą w Warszawie (dalej: „Spółka”) moich danych osobowych w celu wysyłania informacji handlowych drogą elektroniczną, w tym newslettera, zawierającego informacje dotyczące Spółki oraz prowadzonej przez Spółkę działalności. </label>
                </div>

                <div id={"form-terms-2-wrapper"} className={"report-checkbox"}>
                  <input type={"checkbox"} id={"terms-2"} name={"terms-2"} value={1} className={"report-form-checkbox"} required />
                  <label htmlFor={"terms-2"}  className={"report-checkbox-label"}> Wyrażam zgodę na otrzymywanie od Spółki informacji handlowych drogą elektroniczną, w tym newslettera, zawierającego informacje dotyczące Spółki oraz prowadzonej przez Spółkę działalności, na wskazany przeze mnie adres e-mail, zgodnie z art. 10 ust. 1 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną oraz art. 172 ustawy z dnia 16 lipca 2004 roku - Prawo telekomunikacyjne.</label>
                </div>

                <div id={"form-terms-3-wrapper"} className={"report-checkbox"}>
                  <input type={"checkbox"} id={"terms-3"} name={"terms-3"} value={1} className={"report-form-checkbox"} required />
                  <label htmlFor={"terms-3"}  className={"report-checkbox-label"}> Zapoznałam(em) się z <a href="/regulamin-serwisu/" target={"_blank"}>regulaminem</a> i akceptuję jego treść. <a target={"_blank"} href="/polityka-prywatnosci/">Polityka prywatności</a></label>
                </div>


                

                <div className={"terms-txt"}>

                Administratorem Pani/Pana danych osobowych jest spółka Octopus Media Spółka z ograniczona odpowiedzialnością z siedzibą w Warszawie, ul. Domaniewska 39, 02-672 Warszawa (dalej: „Administrator”).<br /> 
                <a href="#" id={"show-full-terms"}>Pokaż pełną treść kaluzuli</a>
                <span id={"full-terms"}>Dodatkowe dane kontaktowe do Administratora: e-mail: recepcja@mullenlowe.pl, numer telefonu: +48 22 31 20 100. Pani/Pana dane osobowe są przetwarzane w szczególności w oparciu o wyrażoną przez Panią/Pana zgodę, w celach wysyłania do Pani/Pana informacji handlowych droga elektroniczną, w tym newslettera, zawierającego informacje dotyczące Administratora oraz prowadzonej przez Administratora działalności. Informujemy, że ma Pani/Pan prawo: dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, a także prawo do przenoszenia danych. Ma Pani/Pan również prawo do cofnięcia zgody na przetwarzanie danych osobowych w dowolnym momencie, z zastrzeżeniem, że cofnięcie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Ma Pani/Pan prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania danych osobowych, opartego na art. 6 ust. 1 lit. f) RODO, z przyczyn związanych z Pani/Pana szczególną sytuacją. Ma Pani/Pan prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych, jeśli przetwarzanie Pani/Pana danych osobowych narusza przepisy RODO. Szczegółowe informacje dotyczące przetwarzania przez Administratora Pani/Pana danych osobowych zawiera polityka prywatności. Zasady dotyczące usługi newslettera określa regulamin świadczenia usług drogą elektroniczną. W jego treści wskazano między innymi, w jaki sposób można zrezygnować z otrzymywania newslettera.</span>

                </div>
                
                 
                <div  className={"mh-button-wrap"}><button id={"report-submit-btn"} className={"mh-button invert"} type={"submit"}>Pobierz</button></div>
          
                
              </form>
              <div id={"report-form-thp"} className={"report-form-thp"}>
                    Na twoją skrzynkę e-mail została wysłana wiadomość z linkiem potwierdzającym adres e-mail.<br />
                    Po jego kliknięciu otrzymasz link do raportu.
                </div>
                <div id={"report-form-thp-existing"}  className={"report-form-thp"}>
                  <p>Adres e-mail istnieje już w bazie.</p>
                  <a href="" id={"report-direct-link"} className={"mh-button invert"}>Przejdź dalej</a>
                </div>
              </div>
              </div>
            }


{showReportFormOrder &&
            
            <div id="raport-zapytaj">

              
            <div id={"report-form-wrapper-order"}>
            <form id={"report-form-order"} method="post" action="/">
                <div className="report-form-title">Skontaktuj się z nami w celu prezentacji pełnej wersji raportu dla Twojej Organizacji</div>
<div class="register-form-col">
                <div id={"form-report-email-order-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-email"} className={"report-form-label"}>
                    Email
                  </label>
                  <input type="email" name={"report-email-order"} id={"report-email-order"} className={"report-form-text-input"} />
                </div>

                <div id={"form-report-full-name-order-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-full-name-order"} className={"report-form-label"}>
                    Imię
                  </label>
                  <input type="text" name={"report-full-name-order"} id={"report-full-name-order"} className={"report-form-text-input"} />
                </div>

                </div>
                <div class="register-form-col">
                <div id={"form-report-company-order-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-company-order"} className={"report-form-label"}>
                    Firma
                  </label>
                  <input type="text" name={"report-company-order"} id={"report-company-order"} className={"report-form-text-input"} />
                </div>

                <div id={"form-report-job-position-order-wrapper"} className={"report-form-row"}>
                  <label htmlFor={"report-job-position-order"} className={"report-form-label"}>
                    Stanowisko
                  </label>
                  <input type="text" name={"report-job-position-order"} id={"report-job-position-order"} className={"report-form-text-input"} />
                </div>
           </div>     

           <div id={"form-report-message-order-wrapper"}>
           <label htmlFor={"report-message-order"} className={"report-form-label"}>
                    Wiadomość
                  </label>
            <textarea id={"report-message-order"} className={"report-form-text-input"} maxLength={255}>

            </textarea>
            
           </div>
               
           <div id={"form-terms-1-order-wrapper"} className={"report-checkbox"}>
                  <input type={"checkbox"} id={"terms-1-order"} name={"terms-1-order"} value={1} className={"report-form-checkbox"} required />
                  <label htmlFor={"terms-1-order"}  className={"report-checkbox-label"}> Wyrażam zgodę na przetwarzanie przez Octopus Media Sp. z o.o. z siedzibą w Warszawie (dalej: „Spółka”) moich danych osobowych w celu wysyłania informacji handlowych drogą elektroniczną, w tym newslettera, zawierającego informacje dotyczące Spółki oraz prowadzonej przez Spółkę działalności. </label>
                </div>

                <div id={"form-terms-2-order-wrapper"} className={"report-checkbox"}>
                  <input type={"checkbox"} id={"terms-2-order"} name={"terms-2-order"} value={1} className={"report-form-checkbox"} required />
                  <label htmlFor={"terms-2-order"}  className={"report-checkbox-label"}> Wyrażam zgodę na otrzymywanie od Spółki informacji handlowych drogą elektroniczną, w tym newslettera, zawierającego informacje dotyczące Spółki oraz prowadzonej przez Spółkę działalności, na wskazany przeze mnie adres e-mail, zgodnie z art. 10 ust. 1 ustawy z dnia 18 lipca 2002 roku o świadczeniu usług drogą elektroniczną oraz art. 172 ustawy z dnia 16 lipca 2004 roku - Prawo telekomunikacyjne.</label>
                </div>

                <div id={"form-terms-3-order-wrapper"} className={"report-checkbox"}>
                  <input type={"checkbox"} id={"terms-3-order"} name={"terms-3-order"} value={1} className={"report-form-checkbox"} required />
                  <label htmlFor={"terms-3-order"}  className={"report-checkbox-label"}> Zapoznałam(em) się z <a href="/regulamin-serwisu/" target={"_blank"}>regulaminem</a> i akceptuję jego treść. <a target={"_blank"} href="/polityka-prywatnosci/">Polityka prywatności</a></label>
                </div>

                

                

                <div className={"terms-txt"}>

                Administratorem Pani/Pana danych osobowych jest spółka Octopus Media Spółka z ograniczona odpowiedzialnością z siedzibą w Warszawie, ul. Domaniewska 39, 02-672 Warszawa (dalej: „Administrator”).<br /> 
                <a href="#" id={"show-full-terms-order"}>Pokaż pełną treść kaluzuli</a>
                <span id={"full-terms-order"}>Dodatkowe dane kontaktowe do Administratora: e-mail: recepcja@mullenlowe.pl, numer telefonu: +48 22 31 20 100. Pani/Pana dane osobowe są przetwarzane w szczególności w oparciu o wyrażoną przez Panią/Pana zgodę, w celach wysyłania do Pani/Pana informacji handlowych droga elektroniczną, w tym newslettera, zawierającego informacje dotyczące Administratora oraz prowadzonej przez Administratora działalności. Informujemy, że ma Pani/Pan prawo: dostępu do swoich danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania, a także prawo do przenoszenia danych. Ma Pani/Pan również prawo do cofnięcia zgody na przetwarzanie danych osobowych w dowolnym momencie, z zastrzeżeniem, że cofnięcie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem. Ma Pani/Pan prawo w dowolnym momencie wnieść sprzeciw wobec przetwarzania danych osobowych, opartego na art. 6 ust. 1 lit. f) RODO, z przyczyn związanych z Pani/Pana szczególną sytuacją. Ma Pani/Pan prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych, jeśli przetwarzanie Pani/Pana danych osobowych narusza przepisy RODO. Szczegółowe informacje dotyczące przetwarzania przez Administratora Pani/Pana danych osobowych zawiera polityka prywatności. Zasady dotyczące usługi newslettera określa regulamin świadczenia usług drogą elektroniczną. W jego treści wskazano między innymi, w jaki sposób można zrezygnować z otrzymywania newslettera.</span>

                </div>
                
                 
                <div  className={"mh-button-wrap"}><button id={"report-submit-btn-order"} className={"mh-button invert"} type={"submit"}>Wyślij</button></div>
          
                
              </form>
              <div id={"report-form-thp-order"} className={"report-form-thp"}>
                    Twoje zgłoszenie zostało wysłane.<br />
                    Dziękujemy!
                </div>
                
              </div>
              </div>
            }

            {showOtherReports &&
            <div>
              <div id="report-download-direct-link-wrapper">
                <a href={"https://mediahubpoland.pl/"+reportLink}  id={"report-download-direct-link"} className={"mh-button"} download>Pobierz raport</a>
              </div>
              <div id="report-sm">
                <span>Bądź na bieżąco z aktualnościami MediaHub</span>
                <div>
                <a href="https://www.facebook.com/MediahubPoland">
                  <img src="/social/facebook.svg" alt="Facebook" />

                  </a>
                  <a href="https://www.instagram.com/mediahubpoland/">
                  <img src="/social/instagram.svg" alt="Instagram" />

                  </a>
                  <a href="https://www.youtube.com/channel/UCDhOExDBfv0vQcoIdAp0a9w">
                  <img src="/social/youtube.svg" alt="Youtube" />

                  </a>
                  
                </div>
              </div>
              <div id="report-other-title">
              Sprawdź również inne nasze opublikowane raporty
                </div>
              <div id="other-reports">

                {pageReport != "" &&

                <div id={"report-list-box"}>
                  {otherReports.map(function(item, i){
  
                    const boxId = item.id + "-box"

                    if(pageReport != item.id) {
                      return <a id={boxId} key={i} href={item.url} className={"report-box-pos"} target="_blank" rel="noreferrer">
                      <div className={"report-news-image-wrapper"}>
                          <img src={item.imageUrl} />
                        </div>
                      <div className={""} style={{maxWidth: '380px'}}>
                        <div className={"report-news-date"}>{item.date}</div>
                        <div className={"report-news-category"}>{item.category}</div>
                        <h4 className={"report-news-title"}>{item.title}</h4>
                        <span className={"report-news-link"}>Czytaj więcej &rarr;</span>
                      </div>
                    </a>
                    } 
                    
                    
                    
                    })}

                </div>
              }


              </div>
            </div>
            }


        </div>
      </section>
      
    </Layout>
  )
}



export default Page



export const query = graphql`
  query($slug: String) {
    wpPage(slug: { eq: $slug }) {
      id
      title
      content
      Description {
        description
      }
      SeoMeta {
        seoTitle
        seoDescription
      }
    }
  }`

  