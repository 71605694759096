import React from "react"
import PropTypes from 'prop-types'
import * as style from './description.module.scss'

const Description = ({ description }) => {
  return (
    <div
      className={style.description}
      dangerouslySetInnerHTML={{ __html: description }}
    />
  )
}

Description.propTypes = {
  description: PropTypes.string.isRequired
}

export default Description